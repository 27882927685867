<mat-toolbar color="primary">
  <button mat-icon-button aria-label="Open Side Menu" (click)="toggleSidenav()">
    <mat-icon *ngIf="!sidenavService.opened">menu</mat-icon>
    <mat-icon *ngIf="sidenavService.opened">close</mat-icon>
  </button>

  <mat-icon>open_in_new</mat-icon>

  <a (click)="navigateToWelcome()">
    <span *ngIf="isOnPage(undefined)">UnitPilot</span>
    <span *ngIf="isOnPage('sales')">UnitPilot - LeadNavigator</span>
    <span *ngIf="isOnPage('user')">UnitPilot - Tempora</span>
    <span *ngIf="isOnPage('admin')">UnitPilot - Administration</span>
  </a>

  <span class="spacer"></span>
  <ng-container>
    <button
      mat-icon-button
      class="mx-1"
      *ngIf="isOnPage('sales') && isTab1Selected"
      (click)="openDeleteDialog()"
      [disabled]="leadService.dealsAndPlansAreEmptySubject$ | async"
      aria-label="Login"
    >
      <mat-icon>delete_outline</mat-icon>
    </button>
  </ng-container>
  <mat-menu #settingMenu="matMenu" yPosition="below" xPosition="before"> </mat-menu>
</mat-toolbar>
