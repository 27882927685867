<div class="h-100 sidenav-content-flex">
  <div class="h1 pt-3 ps-3 mb-1">
    {{ 'menu' | translate }}
  </div>
  <ng-container *ngIf="this.authService.canActivateProtectedRoutes$ | async">
    <mat-nav-list *ngIf="this.authService.hasUserRole$ | async">
      <a mat-list-item (click)="this.sidenavService.close()" routerLink="/user">
        <mat-icon class="me-3">schedule</mat-icon>
        {{ 'user.dashboard' | translate }}
      </a>
    </mat-nav-list>
    <mat-nav-list *ngIf="this.authService.hasSalesRole$ | async">
      <a mat-list-item (click)="this.sidenavService.close()" routerLink="/sales">
        <mat-icon class="me-3">real_estate_agent</mat-icon>
        {{ 'sales.dashboard' | translate }}
      </a>
    </mat-nav-list>
    <mat-nav-list *ngIf="this.authService.hasAdminRole$ | async">
      <a mat-list-item (click)="this.sidenavService.close()" routerLink="/admin">
        <mat-icon class="me-3">manage_accounts</mat-icon>
        {{ 'admin.dashboard' | translate }}
      </a>
    </mat-nav-list>
  </ng-container>
  <mat-nav-list>
    <mat-divider></mat-divider>
    <!--<button mat-menu-item class="mx-1" aria-label="Toggle page theme" (click)="toggleTheme()">
      <mat-icon *ngIf="isDefaultTheme()">light_mode</mat-icon>
      <mat-icon *ngIf="!isDefaultTheme()">nights_stay</mat-icon>
      {{ 'settings.theme' | translate }}
    </button>-->
    <button mat-menu-item class="mx-1" aria-label="Toggle page theme" (click)="toggleWidth()">
      <mat-icon *ngIf="isDefaultWidth()">width_normal</mat-icon>
      <mat-icon *ngIf="!isDefaultWidth()">width_full</mat-icon>
      {{ 'settings.screen-width' | translate }}
    </button>
    <button
      *ngIf="isOnPage('user')"
      mat-menu-item
      class="mx-1"
      aria-label="Timesheet table view"
      matTooltip="{{ 'user.change-timesheet-view' | translate }}"
      (click)="toggleTimesheetTableView()"
    >
      <mat-icon *ngIf="isNewTimesheetTableView">view_stream</mat-icon>
      <mat-icon *ngIf="!isNewTimesheetTableView">table_rows</mat-icon>
      {{ 'user.change-timesheet-view' | translate }}
    </button>
    <a
      mat-list-item
      *ngIf="(this.authService.canActivateProtectedRoutes$ | async) === false"
      aria-label="Login"
      (click)="login()"
    >
      <mat-icon class="mx-1">login</mat-icon>
      {{ 'login' | translate }}
    </a>
    <a
      mat-list-item
      *ngIf="this.authService.canActivateProtectedRoutes$ | async"
      aria-label="Logout"
      (click)="logout()"
    >
      <mat-icon class="mx-1">logout</mat-icon>
      {{ 'logout' | translate }}
    </a>
  </mat-nav-list>
</div>
