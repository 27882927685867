import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private selectedThemeSubject = new BehaviorSubject<string>('default-theme');
  private selectedWidthSubject = new BehaviorSubject<string>('default-width');

  public selectedTheme = 'default-theme';
  public selectedWidth = 'default-width';

  private readonly isDarkSubject$ = new BehaviorSubject<boolean>(false);
  public isDark$ = this.isDarkSubject$.asObservable();

  private readonly isFullWidthSubject$ = new BehaviorSubject<boolean>(false);
  public isFullWidth$ = this.isFullWidthSubject$.asObservable();

  constructor() {}

  public toggleTheme(): void {
    if (this.selectedTheme === 'default-theme') {
      this.selectedTheme = 'alternate-theme';
      this.isDarkSubject$.next(true);
    } else {
      this.selectedTheme = 'default-theme';
      this.isDarkSubject$.next(false);
    }
    window.localStorage.setItem('selectedTheme', 'default-theme');
    this.selectedThemeSubject.next('default-theme');
  }

  public toggleWidth(): void {
    if (this.selectedWidth === 'default-width') {
      this.selectedWidth = 'full-width';
      this.isFullWidthSubject$.next(true);
    } else {
      this.selectedWidth = 'default-width';
      this.isFullWidthSubject$.next(false);
    }
    window.localStorage.setItem('selectedWidth', this.selectedWidth);
    this.selectedWidthSubject.next(this.selectedWidth);
  }

  public setSelectedTheme(theme: string): void {
    this.selectedTheme = theme;
    window.localStorage.setItem('selectedTheme', this.selectedTheme);
    this.isDarkSubject$.next(theme === 'alternate-theme');
    this.selectedThemeSubject.next(theme);
  }

  public setSelectedWidth(width: string): void {
    this.selectedWidth = width;
    window.localStorage.setItem('selectedWidth', this.selectedWidth);
    this.isFullWidthSubject$.next(width === 'full-width');
    this.selectedWidthSubject.next(width);
  }

  public getSelectedThemeObservable(): Observable<string> {
    return this.selectedThemeSubject.asObservable();
  }

  public getSelectedWidthObservable(): Observable<string> {
    return this.selectedWidthSubject.asObservable();
  }

  public isDark(): boolean {
    return this.selectedTheme === 'alternate-theme';
  }

  public isFullWidth(): boolean {
    return this.selectedTheme === 'full-width';
  }
}
